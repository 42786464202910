/**
 * Truncate Text
 */
const KGTruncateText = () => {
    let $truncateText = [...document.querySelectorAll('.kg-truncate-text')];
    let limitHeight = window.innerWidth < 1200 ? 190 : 190;
    if (!$truncateText) {
        return false;
    }
    /**
     * Add Aria Attribute
     * @param {object} el
     */
    function setAriaAttributes(el) {
        if (!el.classList.contains('is-not-truncated')) {
            el.querySelector('.kg-truncate-text-inner').setAttribute('aria-expanded', 'false');
        }
    }

    function toggleInner(el) {
        let $innerContent = el.querySelector('.kg-truncate-text-inner');
        let $button = el.querySelector('.kg-truncate-text-button');
        if (!$button) {
            return false;
        }
        $button.addEventListener('click', (e) => {
            e.preventDefault();
            el.classList.toggle('is-open');
            if ($innerContent.getAttribute('aria-expanded') !== 'true') {
                $innerContent.setAttribute('aria-expanded', 'true');
            } else {
                $innerContent.setAttribute('aria-expanded', 'false');
            }
        });
    }

    function removeTruncatedText(el) {
        let height = el.querySelector('.kg-truncate-text-inner').offsetHeight;
        if (height > limitHeight) {
            el.classList.remove('is-not-truncated');
        } else if (height < limitHeight) {
            el.classList.add('is-not-truncated');
        }
    }

    function initTruncateText(el) {
        removeTruncatedText(el);
        setAriaAttributes(el);
    }

    function init() {
        $truncateText.forEach(el => {
            initTruncateText(el)
            toggleInner(el);
        });
        window.addEventListener('resize', () => {
            setTimeout(() => {
                limitHeight = window.innerWidth < 1200 ? 190 : 190;
                $truncateText.forEach(el => { initTruncateText(el) });
            }, 200);
        })
    }
    init();
}
export default KGTruncateText;