/**
 * Init Function on document Ready
 */

import KGTruncateText from './modules/KGTruncateText';






/* Acces rapide Const */
const openAccesRapideButton = document.querySelector('.btn-mobile-acces-rapide');
const closeAccesRapideButton = document.querySelector('.close-acces-rapide-button');
const containerAccesRapide = document.querySelector('.container-acces-rapide-mobile');

/* Search mobile Const */
const openSearchMobileButton = document.querySelector('.btn-mobile-recherche');
const openSearchMobileHomeButton = document.querySelector('.mobile-home-button-search');
const closeSearchMobileButton = document.querySelector('.close-search-button');
const containerSearchMobile = document.querySelector('.container-search-mobile');

/* Mobile nav Const */
const containerNav = document.querySelector('.container-navigation');
const menuButton = document.querySelector('.btn-mobile-menu');
const itemLinks = [].slice.call(document.querySelectorAll('.item-links'));
const menuSubnavs = [].slice.call(document.querySelectorAll('.uk-navbar-dropdown'));
const closeNavButton = document.querySelector('.close-mobile-nav-button');
const backButtons = [].slice.call(document.querySelectorAll('.back-button-subnav-mobile'));

/* ACCORDEON FOOTER VERSION MOBILE */
const accordeonFooterMobiles = [].slice.call(document.querySelectorAll('.accordeon-footer li.uk-open'));
const accordeonContentFooterMobile = document.querySelector('.uk-accordion-content');

/* DEPLACEMENT DES RS EN VERSION MOBILE  */
const rsContainerMobile = document.querySelector('.container-right-menu');
const headerContainer = document.getElementById('header');

/* OPEN FILTERS LISTING  */
const containerFiltre = document.querySelector('.container-item-filtres');
const buttonFiltre = document.querySelector('.button-filter');
//const closeButtonFiltre = document.getElementById('close-container-filters');

/* OPEN CARS FILTERS LISTING MOBILE  */
const carsFiltersForm = document.getElementById('cars-filters-form');
const buttonOpenCarsFiltersForm = document.querySelector('.open-car-filter');

/* OPEN CONTACT FORM CONCESSION MOBILE  */
const containerForm = document.getElementById('contact-modal');
const buttonOpenContactForm = document.querySelector('.contact-modal-button');

/* OPEN MAP CONCESSION MOBILE  */
const containerMap = document.querySelector('.map');
const buttonOpenMap = document.querySelector('.map-modal-button');

/* Assitances mobile Const */
const openAssitanceMobileButton = document.querySelector('.btn-mobile-assistances');
const closeAssitanceMobileButton = document.querySelector('.close-assistance-button');
const containerAssistanceMobile = document.querySelector('.container-assistance-mobile');


/* GET RESOLUTION / ADD MOBILE CLASS & DESKTOP CLASS  */
function getResolution() {
    if (screen.width < 768) {
        $('body').toggleClass('mobile');
    } else {
        $('body').toggleClass('desktop');
    }
}
/* END */


/* DEPLACEMENT CONTAINER DETAILS VERSION MOBILE  */
function moveContainerDetailsVehiculesMobile() {
    var detailContainer = document.getElementById('container-details');
    var sliderContainer = document.querySelector('.uk-slideshow');
    if (document.body.classList.contains('mobile') && screen.width < 769) {
        if (detailContainer && sliderContainer) {
            sliderContainer.appendChild(detailContainer);
        }
    }
}
/* END */


/* OUVERTURE DU FORMULAIRE CONTACT CONCESSIONS VERSION MOBILE  */
function openContactFormConcessions() {
    if (document.body.classList.contains('mobile')) {
        if (buttonOpenContactForm) {
            buttonOpenContactForm.addEventListener('click', () => {
                containerForm.classList.toggle('uk-open');
            });
        }
    }
}
/* END */


/* OUVERTURE MAP CONCESSIONS VERSION MOBILE  */
function openMapConcessions() {
    if (document.body.classList.contains('mobile')) {
        if (buttonOpenMap) {
            buttonOpenMap.addEventListener('click', () => {
                containerMap.classList.toggle('uk-open');
            });
        }
    }
}
/* END */


/* OUVERTURE DU FORMULAIRE LISTNG CARS EN VERSION MOBILE  */
function openCarsFilterForm() {
    if (document.body.classList.contains('mobile')) {
        if (buttonOpenCarsFiltersForm) {
            buttonOpenCarsFiltersForm.addEventListener('click', () => {
                carsFiltersForm.classList.toggle('open');
            });
        }

    }
}
/* END */


/* OPEN FILTERS LISTING */
function openFilterListing() {
    if (buttonFiltre && containerFiltre) {
        buttonFiltre.addEventListener('click', () => {
            /* if (containerFiltre.classList.contains('open')) {
                containerFiltre.classList.toggle('open');
            } */
            containerFiltre.classList.toggle('open');
            buttonFiltre.classList.toggle('open');
        });
    }
    /* closeButtonFiltre.addEventListener('click', () => {
        containerFiltre.classList.toggle('open');
    }); */
}
/* END */


/* DEPLACEMENT ELEMENTS LOCATION ET SERVICES EN VERSION MOBILE HOME  */
function moveServiceItemMobile() {
    var itemServices1Mobile = document.getElementById('item-service-1-mobile');
    var itemServices2Mobile = document.getElementById('item-service-2-mobile');
    var parentDiv = document.getElementById('container-service-mobile');
    if (document.body.classList.contains('mobile')) {
        if (itemServices1Mobile && itemServices2Mobile) {
            parentDiv.insertBefore(itemServices1Mobile, itemServices2Mobile);
        }
    }
}
/* END */


/* DEPLACEMENT container RS VERSION MOBILE  */
function moveContainerRsMobile() {
    if (document.body.classList.contains('mobile')) {
        headerContainer.appendChild(rsContainerMobile);
    }
}
/* END */


/* GESTION ACCORDEON CONCESSION FOOTER  */
function accordeonFooter() {
    if (document.body.classList.contains('mobile')) {
        accordeonFooterMobiles.forEach(function(accordeonFooterMobile) {
            accordeonFooterMobile.classList.toggle('uk-open');
        });
    }
}
/* END */


/* ACCES RAPIDE MOBILE */
function openAccesRapideMobile() {
    if (openAccesRapideButton) {
        openAccesRapideButton.addEventListener('click', () => {
            if (containerNav.classList.contains('open')) {
            containerNav.classList.toggle('open');
        }
        if (containerSearchMobile.classList.contains('open')) {
            containerSearchMobile.classList.toggle('open');
        }
        if (containerAssistanceMobile.classList.contains('open')) {
            containerAssistanceMobile.classList.toggle('open');
        }
        containerAccesRapide.classList.toggle('open');
        openAccesRapideButton.classList.toggle('active');
    });
    }
    if (closeAccesRapideButton) {
        closeAccesRapideButton.addEventListener('click', () => {
            containerAccesRapide.classList.toggle('open');
        openAccesRapideButton.classList.toggle('active');
    });
    }
}
/* END */


/* ASSISTANCE MOBILE */
function openAssistanceMobile() {
    if (openAssitanceMobileButton) {
        openAssitanceMobileButton.addEventListener('click', () => {
            if (containerNav.classList.contains('open')) {
            containerNav.classList.toggle('open');
        }
        if (containerSearchMobile.classList.contains('open')) {
            containerSearchMobile.classList.toggle('open');
        }
        if (containerAccesRapide.classList.contains('open')) {
            containerAccesRapide.classList.toggle('open');
        }
        containerAssistanceMobile.classList.toggle('open');
        openAssitanceMobileButton.classList.toggle('active');
    });
    }
    if (closeAssitanceMobileButton) {
        closeAssitanceMobileButton.addEventListener('click', () => {
            containerAssistanceMobile.classList.toggle('open');
        openAssitanceMobileButton.classList.toggle('active');
    });
    }
}
/* END */


/* SEARCH MOBILE */
function openSearchMobile() {
    if (openSearchMobileHomeButton) {
        openSearchMobileHomeButton.addEventListener('click', () => {
            containerSearchMobile.classList.toggle('open');
            if (containerNav.classList.contains('open')) {
                containerNav.classList.toggle('open');
            }
        });
    }
    if (openSearchMobileButton) {
        openSearchMobileButton.addEventListener('click', () => {
            containerSearchMobile.classList.toggle('open');
        openSearchMobileButton.classList.toggle('active');
        if (containerNav.classList.contains('open')) {
            containerNav.classList.toggle('open');
        }
        if (containerAccesRapide.classList.contains('open')) {
            containerAccesRapide.classList.toggle('open');
        }
        if (containerAssistanceMobile.classList.contains('open')) {
            containerAssistanceMobile.classList.toggle('open');
        }
    });
    }
    if (closeSearchMobileButton) {
        closeSearchMobileButton.addEventListener('click', () => {
            containerSearchMobile.classList.toggle('open');
        openSearchMobileButton.classList.toggle('active');
    });
    }
}
/* END */


/* NAVIGATION MOBILE OPEN MENU */
function OpenSubMenu(itemLink, menuSubNav) {
    if (itemLink) {
        itemLink.addEventListener('click', (e) => {
            if (itemLink.classList.contains('uk-open')) {
            itemLink.classList.toggle('uk-open');
        }
        if (menuSubNav.classList.contains('fade-out')) {
            menuSubNav.classList.toggle('fade-out');
            menuSubNav.classList.toggle('uk-open');
        }
        UIkit.dropdown(menuSubNav).show();
    });
    }
}
/* END */


/* CLOSE MENU */
function closeSubMenu(backButton, menuSubnav) {
    if (backButton) {
        backButton.addEventListener('click', (e) => {
        (backButton.parentElement).classList.toggle('fade-out');
        (backButton.parentElement).classList.toggle('uk-open');
        UIkit.dropdown(menuSubnav).hide(100);
    });
    }
}
/* END */


/* MOBILE NAV */
function mobileNav() {
    if (document.body.classList.contains('mobile')) {
        if (menuButton) {
            menuButton.addEventListener('click', () => {
                if (containerAccesRapide.classList.contains('open')) {
                containerAccesRapide.classList.toggle('open');
            }
            if (containerSearchMobile.classList.contains('open')) {
                containerSearchMobile.classList.toggle('open');
            }
            if (containerAssistanceMobile.classList.contains('open')) {
                containerAssistanceMobile.classList.toggle('open');
            }
            containerNav.classList.toggle('open');
        });
        }
        if (closeNavButton) {
            closeNavButton.addEventListener('click', () => {
                containerNav.classList.toggle('open');
        });
        }

        itemLinks.forEach(function(itemLink) {
            var menuSubNavItem = itemLink.parentNode.querySelector('.uk-navbar-dropdown');
            var backButtonItem = menuSubNavItem.querySelector('.back-button-subnav-mobile');
            OpenSubMenu(itemLink, menuSubNavItem);
            closeSubMenu(backButtonItem, menuSubNavItem);
        });
    }
}
/* END */



/* RANGE MOBILE / DESKTOP */
function openRangeModalsMobile() {
    var modalPriceButton = document.querySelector('.button-modal-prix-mobile');
    var modalPrice = document.querySelector('.container-prix-mobile');
    var modalMensualButton = document.querySelector('.button-modal-mensualites-mobile');
    var modalMensual = document.querySelector('.container-range-modal-mensualites-mobile');
    var closeButtonPrice = document.querySelector('.close-range-modal-prix-mobile');
    var closeButtonMensual = document.querySelector('.close-range-modal-mensualites-mobile');
    var validationButtonPrice = document.querySelector('.validate-price-range-button-mobile');
    var validationButtonMensual = document.querySelector('.validate-mensualites-range-button-mobile');
    if (modalPriceButton) {
        modalPriceButton.addEventListener('click', () => {
            if (modalMensual.classList.contains('modal-open')) {
                modalMensualButton.classList.toggle('open');
                modalMensual.classList.toggle('modal-open');
            }
            modalPriceButton.classList.toggle('open');
            modalPrice.classList.toggle('modal-open');
        });
    }

    if (modalMensualButton) {
        modalMensualButton.addEventListener('click', () => {
            if (modalPrice.classList.contains('modal-open')) {
            modalPriceButton.classList.toggle('open');
            modalPrice.classList.toggle('modal-open');
        }
        modalMensualButton.classList.toggle('open');
        modalMensual.classList.toggle('modal-open');
    });
    }

    if (closeButtonPrice) {
        closeButtonPrice.addEventListener('click', () => {
            modalPriceButton.classList.toggle('open');
            modalPrice.classList.toggle('modal-open');
        });
    }
    if (validationButtonPrice) {
        validationButtonPrice.addEventListener('click', () => {
            modalPriceButton.classList.toggle('open');
            modalPrice.classList.toggle('modal-open');
        });
    }
    if (closeButtonMensual) {
        closeButtonMensual.addEventListener('click', () => {
            modalMensualButton.classList.toggle('open');
            modalMensual.classList.toggle('modal-open');
        });
    }
    if (validationButtonMensual) {
        validationButtonMensual.addEventListener('click', () => {
            modalMensualButton.classList.toggle('open');
            modalMensual.classList.toggle('modal-open');
        });
    }

    var modalPriceButton_2 = document.querySelector('.button-modal-prix-mobile-2');
    var modalPrice_2 = document.querySelector('.container-prix-mobile-2');
    var modalMensualButton_2 = document.querySelector('.button-modal-mensualites-mobile-2');
    var modalMensual_2 = document.querySelector('.container-range-modal-mensualites-mobile-2');
    var closeButtonPrice_2 = document.querySelector('.close-range-modal-prix-mobile-2');
    var closeButtonMensual_2 = document.querySelector('.close-range-modal-mensualites-mobile-2');
    var validationButtonPrice_2 = document.querySelector('.validate-price-range-button-mobile-2');
    var validationButtonMensual_2 = document.querySelector('.validate-mensualites-range-button-mobile-2');
    if (modalPriceButton_2) {
        modalPriceButton_2.addEventListener('click', () => {
            if (modalMensual_2.classList.contains('modal-open')) {
            modalMensualButton_2.classList.toggle('open');
            modalMensual_2.classList.toggle('modal-open');
        }
        modalPriceButton_2.classList.toggle('open');
        modalPrice_2.classList.toggle('modal-open');
    });
    }
    if (modalMensualButton_2) {
        modalMensualButton_2.addEventListener('click', () => {
            if (modalPrice_2.classList.contains('modal-open')) {
                modalPriceButton_2.classList.toggle('open');
                modalPrice_2.classList.toggle('modal-open');
            }
            modalMensualButton_2.classList.toggle('open');
            modalMensual_2.classList.toggle('modal-open');
        });
    }

    if (closeButtonPrice_2) {
        closeButtonPrice_2.addEventListener('click', () => {
            modalPriceButton_2.classList.toggle('open');
            modalPrice_2.classList.toggle('modal-open');
        });
    }
    if (validationButtonPrice_2) {
        validationButtonPrice_2.addEventListener('click', () => {
            modalPriceButton_2.classList.toggle('open');
            modalPrice_2.classList.toggle('modal-open');
        });
    }
    if (closeButtonMensual_2) {
        closeButtonMensual_2.addEventListener('click', () => {
            modalMensualButton_2.classList.toggle('open');
            modalMensual_2.classList.toggle('modal-open');
        });
    }
    if (validationButtonMensual_2) {
        validationButtonMensual_2.addEventListener('click', () => {
            modalMensualButton_2.classList.toggle('open');
            modalMensual_2.classList.toggle('modal-open');
        });
    }
}

function openRangeModalsDesktop() {
    var modalPriceButton = document.querySelector('.button-modal-prix-desktop');
    var modalPrice = document.querySelector('.container-prix-desktop');
    var modalMensualButton = document.querySelector('.button-modal-mensualites-desktop');
    var modalMensual = document.querySelector('.container-mensualites-desktop');
    var closeButtonPrice = document.querySelector('.close-range-modal-prix-desktop');
    var closeButtonMensual = document.querySelector('.close-range-modal-mensualites-desktop');
    var validationButtonPrice = document.querySelector('.validate-price-range-button');
    var validationButtonMensual = document.querySelector('.validate-mensualites-range-button');

    if (modalPriceButton) {
        modalPriceButton.addEventListener('click', () => {
            if (modalMensual.classList.contains('modal-open')) {
                modalMensualButton.classList.toggle('open');
                modalMensual.classList.toggle('modal-open');
            }
            modalPriceButton.classList.toggle('open');
            modalPrice.classList.toggle('modal-open');
        });
        closeButtonPrice.addEventListener('click', () => {
            modalPriceButton.classList.toggle('open');
            modalPrice.classList.toggle('modal-open');
        });
        validationButtonPrice.addEventListener('click', () => {
            modalPriceButton.classList.toggle('open');
            modalPrice.classList.toggle('modal-open');
            if ($(modalPrice).closest('form').hasClass('autosubmit')) {
                $(modalPrice).closest('form').submit();
            }
        });
    }
    if (modalMensualButton) {
        modalMensualButton.addEventListener('click', () => {
            if (modalPrice.classList.contains('modal-open')) {
                modalPriceButton.classList.toggle('open');
                modalPrice.classList.toggle('modal-open');
            }
            modalMensualButton.classList.toggle('open');
            modalMensual.classList.toggle('modal-open');
        });

        closeButtonMensual.addEventListener('click', () => {
            modalMensualButton.classList.toggle('open');
            modalMensual.classList.toggle('modal-open');
        });
        validationButtonMensual.addEventListener('click', () => {
            modalMensualButton.classList.toggle('open');
            modalMensual.classList.toggle('modal-open');
            if ($(modalPrice).closest('form').hasClass('autosubmit')) {
                $(modalMensual).closest('form').submit();
            }
        });
    }
}

function rangeBudgetMobile() {
    var rangeBudgetMobile = document.querySelectorAll('.slider-budget-mobile');
    if (rangeBudgetMobile) {
        rangeBudgetMobile.forEach(element => {

            noUiSlider.create(element, {
                start: [prix_min, prix_max],
                connect: true,
                tooltips: false,
                format: wNumb({
                    decimals: 0,
                    suffix: ' €',
                    thousand: ' '
                }),
                range: {
                    'min': 0,
                    'max': prix_max_vehicule
                },
                step: 500
            });
            
            var nonLinearStepSliderValueElement = document.querySelectorAll('.slider-budget-range-value-mobile');
            var inputMin = document.querySelector('.container-prix-mobile .prix_min, .container-prix-mobile-2 .prix_min');
            var inputMax = document.querySelector('.container-prix-mobile .prix_max, .container-prix-mobile-2 .prix_max');
    
            element.noUiSlider.on('update', function(values) {
                nonLinearStepSliderValueElement.forEach(nonLinear => {
                    nonLinear.innerHTML = 'Entre <span class="montant">' + values.join("</span> et <span class='montant'>") + '</span>';
                });
                inputMin.value = values[0];
                inputMax.value = values[1];
            });

        })
    }

}

function rangeBudgetDesktop() {
    var rangeBudgetDesktop = document.querySelector('.slider-budget-desktop');
    if (rangeBudgetDesktop) {
        noUiSlider.create(rangeBudgetDesktop, {
            start: [prix_min, prix_max],
            connect: true,
            tooltips: false,
            format: wNumb({
                decimals: 0,
                suffix: ' €',
                thousand: ' '
            }),
            range: {
                'min': 0,
                'max': prix_max_vehicule
            },
            step: 500
        });

        var nonLinearStepSliderValueElement = document.querySelector('.slider-budget-range-value-desktop');
        var inputMin = document.querySelector('.container-prix-desktop .prix_min');
        var inputMax = document.querySelector('.container-prix-desktop .prix_max');

        rangeBudgetDesktop.noUiSlider.on('update', function(values) {
            nonLinearStepSliderValueElement.innerHTML = 'Entre <span class="montant">' + values.join("</span> et <span class='montant'>") + '</span>';
            inputMin.value = values[0];
            inputMax.value = values[1];
        });
    }
}

function rangeMensualitesMobile() {

    var rangeMensualitesMobile = document.querySelectorAll('.slider-mensualites-mobile');
    
    if (rangeMensualitesMobile) {

        rangeMensualitesMobile.forEach(element => {
         
            noUiSlider.create(element, {
                start: [mensualite_min, mensualite_max],
                connect: true,
                format: wNumb({
                    decimals: 0,
                    suffix: ' €',
                    thousand: ' '
                }),
                range: {
                    'min': [0],
                    'max': [1000]
                }
            });
    
            var rangeSliderValueElement = document.querySelectorAll('.slider-mensualites-range-value-mobile');
            var inputMin = document.querySelector('.container-mensualites-mobile .prix_min, .container-mensualites-mobile-2 .prix_min');
            var inputMax = document.querySelector('.container-mensualites-mobile .prix_max, .container-mensualites-mobile-2 .prix_max');
    
            element.noUiSlider.on('update', function(values) {
                rangeSliderValueElement.forEach( range => {
                    range.innerHTML = 'Entre <span class="montant">' + values.join("</span> et <span class='montant'>") + '</span>/Mois';
                })
                inputMin.value = values[0];
                inputMax.value = values[1];
            });

        });

    }
}

function rangeMensualitesDesktop() {
    var rangeMensualitesDesktop = document.querySelector('.slider-mensualites-desktop');
    if (rangeMensualitesDesktop) {
        noUiSlider.create(rangeMensualitesDesktop, {
            start: [mensualite_min, mensualite_max],
            format: wNumb({
                decimals: 0,
                suffix: ' €',
                thousand: ' '
            }),
            range: {
                'min': [0],
                'max': [1000]
            }
        });

        var rangeSliderValueElement = document.querySelector('.slider-mensualites-range-value-desktop');
        var inputMin = document.querySelector('.container-mensualites-desktop .prix_min');
        var inputMax = document.querySelector('.container-mensualites-desktop .prix_max');

        rangeMensualitesDesktop.noUiSlider.on('update', function(values) {
            rangeSliderValueElement.innerHTML = 'Entre <span class="montant">' + values.join("</span> et <span class='montant'>") + '</span>/Mois';
            inputMin.value = values[0];
            inputMax.value = values[1];
        });
    }

}
/* END RANGE MOBILE / DESKTOP */

function filtersVehicules() {
    $('#cars-filters-form select.filter-select').on('change', function() {
        if ($(this).attr('id') != "filter_select_modeles" || $(this).val() != "") {
            UIkit.filter('[uk-filter="target: .js-filter"]', { target: ".js-filter" } ).apply( $($(this).val()) );
        } else {
            UIkit.filter('[uk-filter="target: .js-filter"]', { target: ".js-filter" } ).apply( $($('#filter_select_marques option:selected').val()) );
        }

        setTimeout(function() {
            updateVehiculeCount();
        }, 300);
    });

    updateVehiculeCount();

    $('#filter_select_marques').on('change', function() {
        var id_marque = $(this).find('option:selected').attr('data-id');

        $('#filter_select_modeles').show();

        if (id_marque) {
            $('#filter_select_modeles option').hide();
            $('#filter_select_modeles option[data-marque='+id_marque+']').show();
            $('#filter_select_modeles option[value=""]').show();
        }

        $('#filter_select_modeles').val('');
    });

    $('#cars-filters-form select.submitonchange').on('change', function() {
        $(this).closest('form').submit();
    });

    $('.redirect-to-modele').on('change', function() {
        var redirection = $(this).closest('form').attr('action') + '/' + $(this).val();
        window.location.href = redirection;
    });
}

function updateVehiculeCount() {
    var nb_vehicules = 0;
    $('.js-filter li').each(function() {
        if ($(this).css('display') != 'none') {
            if ($(this).attr('data-nb-vehicules')) {
                nb_vehicules += parseInt($(this).attr('data-nb-vehicules'));
            } else {
                nb_vehicules = 0;
            }
        }
    });
    if (nb_vehicules !== 0) {
        $('.result-search-form .number-of-cars').html(nb_vehicules);
    }
}


function filtersCarsForm() {
    $('.search-form-car select[name=id_type]').on('change', function() {
        var form = $(this).closest('.search-form-car');
        var id_type = $(this).val();

        form.find('select[name=id_marque]').val('');
        form.find('select[name=id_modele]').val('');

        form.find('select[name=id_marque] option:not([value=""])').css('display','none');
        form.find('select[name=id_modele] option:not([value=""])').css('display','none');

        form.find('select[name=id_marque] option[data-type='+id_type+']').css('display','block');
        form.find('select[name=id_modele] option[data-type='+id_type+']').css('display','block');

        if (id_type == 1) {
            form.find('.container-budget').show();
        } else {
            form.find('.container-budget').hide();
        }
    });
    $('.search-form-car select[name=id_marque]').on('change', function() {
        var form = $(this).closest('.search-form-car');
        var id_type = form.find('select[name=id_type]').val();
        var id_marque = $(this).val();

        form.find('select[name=id_modele]').val('');

        form.find('select[name=id_modele] option:not([value=""])').css('display','none');

        form.find('select[name=id_modele] option[data-marque='+id_marque+'][data-type='+id_type+']').css('display','block');
        console.log('select[name=id_modele] option[data-marque='+id_marque+'][data-type='+id_type+']');
    });
}

function filtersContactForm() {
    if ($(".essai-form").length) {
        updateMarqueConcessionModele($('.contact-form select[name=id_marque]').val());
        $('.contact-form select[name=id_marque]').on('change', function(e) {
            updateMarqueConcessionModele($('.contact-form select[name=id_marque]').val());
        });

    } else {
        updateContactServices($('.contact-form select[name=id_concession]').val());
        updateVehiculeListing($('.contact-form select[name=id_concession]').val());
        $('.contact-form select[name=id_concession]').on('change', function(e) {
            updateContactServices($(this).val());
            updateVehiculeListing($(this).val());
        });
    }

}

function updateContactServices(id_concession) {
    if (id_concession) {
        $('.select-service-container').slideDown();
        $('.select-service-container select').val('');
        $('.select-service-container option[data-id-concession]:not([data-id-concession='+id_concession+'])').hide();
        $('.select-service-container option[data-id-concession='+id_concession+']').show();

        $(".select-service-container select option").each(function() {
            $(this).remove();
        });

        $('.select-service-container select').append('<option value="">Choisissez le service *</option>');
        services.forEach((element) => {
            if(element[0] == id_concession){
                $('.select-service-container select').append('<option data-id-concession="' + element[0] + '" value="' + element[1] + '">' + element[2] + '</option>');
            }
        });
    } else {
        $('.select-service-container').slideUp();
    }
}

function updateVehiculeListing(id_concession) {
    if (id_concession) {
        $('.select-vehicule-container').slideDown();
        $('.select-vehicule-container select[name="id_modele"]').val('');
        $('.select-vehicule-container select[name="id_modele"] optgroup').hide();

        if ($('.contact-form select[name=id_concession] option:selected').attr('data-marques')) {
            var marques_concession = JSON.parse($('.contact-form select[name=id_concession] option:selected').attr('data-marques'));
            marques_concession.forEach(function (element, key) {
                $('.select-vehicule-container select[name="id_modele"] optgroup').each(function() {
                    var label = $(this).attr('label').toLowerCase();
                    if (element === label) {
                        $(this).show();
                    }
                });
            });
        }
    } else {
        $('.select-vehicule-container').slideUp();
    }
}

function updateMarqueConcessionModele(id_marque) {
    if (id_marque) {
        $('.select-vehicule-container').slideDown();
        $('.select-vehicule-container select').val('');
        $('.select-vehicule-container select[name="id_modele"] optgroup').hide();
        $('.select-vehicule-container select[name="id_modele"] optgroup[data-id-marque='+id_marque+']').show();

        $('.select-concession-container').slideDown();
        $('.select-concession-container select').val('');
        $('.select-concession-container select[name="id_concession"] option').hide();
        $('.contact-form select[name=id_concession] option').each(function() {
            var option = $(this);
            if ($(this).attr('data-marques')) {
                var marques_concession = JSON.parse($(this).attr('data-marques'));
                marques_concession.forEach(function(element) {
                    if (element == id_marque) {
                        option.show();
                    }
                });
            }
        });
    } else {
        $('.select-vehicule-container').slideUp();
    }
}

var recaptchaContactOk;
function initReCaptcha() {
    $('.captcha-form').on('submit', function(e) {
        var form = $(this);
        if (!recaptchaContactOk) {
            e.preventDefault();
            grecaptcha.ready(function() {
                grecaptcha.execute(reCAPTCHA_sitekey, {action: 'user_inscription'}).then(function(token) {
                    form.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                    recaptchaContactOk = true;
                    form.submit();
                });
            });
        }
    });
}

function initFormAutosubmit() {
    $('form.autosubmit input, form.autosubmit select').on('change', function() {

        if ($(this).closest('form').find('input[name=page]').length) {
            $(this).closest('form').find('input[name=page]').val(1);
        }
        $(this).closest('form').submit();
    });
}

function initFormVo() {
    $('.form-vo').on('submit', function(e) {

        var action = $('#form-base-url').val();
        var segments = 0;

        if (segments < 2 && $('.form-vo select[name=id_marque]').val()) {
            action += "/"+$('.form-vo select[name=id_marque] option:selected').attr("data-slug");
            segments++;
        }
        if (segments < 2 && $('.form-vo input[name="id_concession[]"]:checked').length === 1) {
            action += "/"+$('.form-vo input[name="id_concession[]"]:checked').attr("data-slug");
            segments++;
        }
        if (segments < 2 && $('.form-vo select[name=id_modele]').val()) {
            action += "/"+$('.form-vo select[name=id_modele] option:selected').attr("data-slug");
            segments++;
        }

        $('.form-vo').attr('action', action);
    })
}

function initVoPagination() {
    $('.vo-pagination li a').on('click', function(e) {
        e.preventDefault();
        $('.form-vo input[name=page]').val($(this).attr('data-ci-pagination-page'));
        $('.form-vo').submit();
    })
}

function initComparateur() {

    $('.add-favoris').on('click', function(event) {

        event.preventDefault();

        var id_vehicule = $(this).data("id");
        var button = $(this);

        $.ajax({
            url: base_url+"comparateur/add_delete/"+id_vehicule,
            type: "get",

            success: function(data) {

                $('div.alert_message').remove();

                var donnees = data.split("||");
                if (donnees[0] == "ok") {
                    $("body").prepend("<div class='alert_message success'>"+donnees[1]+"</div>");

                    button.toggleClass('active');
                    if (donnees[2] == 'add') {
                        if (button.hasClass('active')) {
                            flyElement(button);
                        }
                    }
                } else {
                    $("body").prepend("<div class='alert_message error'>"+donnees[1]+"</div>");
                }
                $(".nb_comparateur").html(donnees[3]);
                $('div.alert_message').delay(3000).fadeOut('slow', function() { $(this).remove(); });
            }
        });
    });

    $(".btn_supp.comparateur").click(function() {
        if (confirm("Etes vous sûr de vouloir supprimer ce véhicule de vos favoris ?")) {
            var id_vehicule = $(this).data("id");
            $.ajax({
                url: base_url+"comparateur/add_delete/"+id_vehicule,
                type: "get",

                success: function(data) {
                    var donnees = data.split("||");
                    if (donnees[3] == 0) {
                        document.location.href = base_url + "comparateur/vide";
                    } else {
                        $(".infos_"+id_vehicule).fadeOut(500, function() {
                            $(".nb_comparateur").html(donnees[3]);
                            $(".infos_"+id_vehicule).remove();
                        });
                    }
                }
            });
        }
    });

    $("#vider_comparateur").click(function() {
        if (confirm("Etes vous sûr de vouloir vider le comparateur ?")) {
            document.location.href = base_url + "comparateur/vide";
        }
    });
}

function flyElement(element) {
    /* Get Position and Size of THIS */
    var initHeight = element.outerHeight();
    var initWidth = element.outerWidth();
    /* Initial */
    var posInitTop = element.offset().top;
    var posInitLeft = element.offset().left;
    /* Final */
    var posFinalTop = $('.item-acces-rapide-1').offset().top;
    var posFinalLeft = $('.item-acces-rapide-1').offset().left;

    /* Duplicate using THIS properties */
    $('body').append("<div class='fly'></div>");

    /* Define fly element properties */
    $('.fly').css({

        overflow: 'hidden',
        position: 'absolute',
        height: initHeight,
        width: initWidth,
        top: posInitTop,
        border: '2px solid #0F5CBF',
        background: '#ffffff',
        left: posInitLeft
    });

    /* fadeIn */
    setTimeout(function() {
        $('.fly')
            .animate({ opacity: 1 }, 10)
            .animate({
                borderRadius: '50%',
                width: 40,
                height: 40,
                marginLeft: initWidth / 2 - initHeight / 2
            }, 300)
            .animate({
                top: posFinalTop + 8,
                left: posFinalLeft + 23
            }, 600)
            .animate({
                opacity: 0
            }, 600);
    }, 20);
    setTimeout(function() {
        $('.fly').remove();
    }, 1600);
}

function initFonctionsVO() {
    $('.button-financement').click(function() {

        var target = $('#onglets-vo');
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top-120
            }, 1700, function() {

                $('#button-onglet-financement').trigger('click');
                $('.uk-accordion li').removeClass('uk-open');
                $('.uk-accordion li .uk-accordion-content').attr('aria-hidden', true);
                $('.uk-accordion li .uk-accordion-content').prop('hidden', true);
                $('#button-onglet-financement').parent().addClass('uk-open');
                $('#button-onglet-financement').parent().find('.uk-accordion-content').attr('aria-hidden', false);
                $('#button-onglet-financement').parent().find('.uk-accordion-content').prop('hidden', false);

                target.focus();
            });
        }
    });


    $('.more-link').click(function() {

        var target = $('#description_complete');
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top-120
            }, 1700);
        }
    });

    $('.share-button').click(function() {
        $(this).next('.pop-buttons').toggleClass('active');
    });

    $('.button-essai').click(function() {
        var target = $('#form-contact-vo');
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top-120
            }, 1700, function() {
                target.focus();
            });
        }

        if ($('#form-contact-vo .title').hasClass('changed')) {
            var new_title = "Nous contacter";
            $('.button-essai').html("Demander un essai");
            $('.contact-form input[name=action]').val('send_contact_vo');
        } else {
            var new_title = "Demander un essai";
            $('.button-essai').html("Nous contacter");
            $('.contact-form input[name=action]').val('send_contact_essai');
        }
        $('#form-contact-vo .title').toggleClass('changed');

        var time_offset = 0;

        for (var i=0; i<$('#form-contact-vo .title').html().length; i++) {
            time_offset += 85;
            setTimeout(function() {
                var length = $('#form-contact-vo .title').html().length;
                var new_content = $('#form-contact-vo .title').html().substr(0, length-1);

                $('#form-contact-vo .title').html(new_content);
            }, i*85);
        }

        for (var j=0; j<new_title.length; j++) {
            setTimeout(function() {
                var length = $('#form-contact-vo .title').html().length;
                var new_content = new_title.substr(0, length+1);

                $('#form-contact-vo .title').html(new_content);
            }, time_offset+(j*100));
        }
    });
}


function initSlideNumber() {
    $('.uk-slideshow-items li').on('itemshow', function() {
        $('.promo .slide_number').html($(this).attr('data-slide-number')+' / '+$(this).closest('.uk-slideshow-items').find('li').length);
    });
}

function initFormRdv() {

    if ($('.form-rdv-atelier input[name="civilite"]:checked').val() === "Société") {
        $('.form-rdv-atelier .row-nom').hide();
        $('.form-rdv-atelier .row-prenom').hide();
        $('.form-rdv-atelier .row-nomination').show();
    } else {
        $('.form-rdv-atelier .row-nom').show();
        $('.form-rdv-atelier .row-prenom').show();
        $('.form-rdv-atelier .row-nomination').hide();
    }

    $('.form-rdv-atelier input[name="civilite"]').on('change', function() {
        if ($('.form-rdv-atelier input[name="civilite"]:checked').val() === "Société") {
            $('.form-rdv-atelier .row-nom').hide();
            $('.form-rdv-atelier .row-prenom').hide();
            $('.form-rdv-atelier .row-nomination').show();
        } else {
            $('.form-rdv-atelier .row-nom').show();
            $('.form-rdv-atelier .row-prenom').show();
            $('.form-rdv-atelier .row-nomination').hide();
        }
    });

    $(".form-rdv-atelier select[name='id_concession'], .form-rdv-atelier select[name='id_service_atelier']").on('change', function() {
        var id_concession = $(".form-rdv-atelier select[name='id_concession']").val();
        var id_service = $(".form-rdv-atelier select[name='id_service_atelier']").val();
        $('.line-select-hour').fadeOut();
        $('.infos-retour .retour').html("");
        $('.infos-retour').fadeOut();

        if (id_concession !== "" && id_service !== "") {

            $('.form-rdv-atelier .form-atelier-overlay').fadeIn();
            $.ajax({
                url: base_url+'rdv/checkTimesSlots/'+id_concession+'/'+id_service
            }).done(function(data) {

                if (data != "") {
                    var retour = JSON.parse(data);

                    if (retour.status === "01") {
                        $('.infos-rdv .intervention').html(retour.intervention);
                        $('.infos-rdv .immobilisation').html(retour.immobilisation);


                        $('.form-rdv-atelier select[name="date"]').val('');
                        $('.form-rdv-atelier select[name="date"] option:not([value=""])').remove();

                        retour.jours.forEach(function(jour) {
                            $('.form-rdv-atelier select[name="date"]').append(
                                $('<option />', {
                                    value: jour.date,
                                    html: jour.libelle
                                }).attr("data-hours", JSON.stringify(jour.heures))
                            );
                        });

                        $('.container-form .uk-alert-danger').fadeOut();
                        $('.infos-rdv-hidden').slideDown();
                    } else {
                        $('.container-form .uk-alert-danger p').html("Une erreur s'est produite lors de la récupération des créneaux.");
                        $('.container-form .uk-alert-danger').fadeIn();
                        $('.infos-rdv-hidden').slideUp();
                    }

                } else {
                    $('.container-form .uk-alert-danger p').html("Une erreur s'est produite lors de la récupération des créneaux.");
                    $('.container-form .uk-alert-danger').fadeIn();
                    $('.infos-rdv-hidden').slideUp();
                }

                $('.form-rdv-atelier .form-atelier-overlay').fadeOut();

            }).fail(function() {
                $('.container-form .uk-alert-danger p').html("Une erreur s'est produite lors de la récupération des créneaux.");
                $('.container-form .uk-alert-danger').fadeIn();
                $('.infos-rdv-hidden').slideUp();
                $('.form-rdv-atelier .form-atelier-overlay').fadeOut();
            });
        }
    });

    $(".form-rdv-atelier select[name='date']").on('change', function() {

        $('.infos-retour .retour').html("");
        $('.infos-retour').fadeOut();
        $('.form-rdv-atelier select[name="hour"]').val('');
        $('.form-rdv-atelier select[name="hour"] option:not([value=""])').remove();

        if ($(this).val()) {
            $('.line-select-hour').fadeIn();

            var hours = JSON.parse($(this).find('option:selected').attr('data-hours'));

            hours.forEach(function(hour) {
                $('.form-rdv-atelier select[name="hour"]').append(
                    $('<option />', {
                        value: hour.startHour,
                        html: hour.startHour.substring(0,5).replace(':','h'),
                    }).attr("data-return-date", hour.expectedReturnDate)
                );
            });
        } else {
            $('.line-select-hour').fadeOut();
        }

    });

    $(".form-rdv-atelier select[name='hour']").on('change', function() {
        $('.infos-retour .retour').html($(this).find('option:selected').attr('data-return-date'));
        $('.infos-retour').fadeIn();
    });
}

function initSEOListings() {
    if ($('#cars-filters-form.form-vd').length) {
        updateSeoVD();
        $('#cars-filters-form.form-vd select').on('change', function() {
            updateSeoVD(true);
        });
    }
    if ($('#cars-filters-form.form-vfkm').length) {
        updateSeoVFKM();
        $('#cars-filters-form.form-vfkm select').on('change', function() {
            updateSeoVFKM(true);
        });
    }
}

function updateSeoVD(pushState=false) {
    if (!$('.section-background-header h1 span').length) {
        $('.section-background-header h1').html($('.section-background-header h1').html()+"<span></span>");
    }
    var txt_append = "";
    var new_slug = "";
    if ($('#filter_select_marques').val()) {
        txt_append += $('#filter_select_marques option:selected').html()+" ";
        new_slug += $('#filter_select_marques option:selected').attr('data-slug')+'/';

        if ($('#filter_select_modeles').val()) {
            new_slug += $('#filter_select_modeles option:selected').attr('data-slug');
        }
    }
    if ($('#filter_select_modeles').val()) {
        txt_append += $('#filter_select_modeles option:selected').html();
    }
    $('.section-background-header h1 span').html(txt_append);
    if (pushState) {
        history.pushState({}, "", base_url+'vehicules-de-demonstration/'+new_slug);
    }
}
function updateSeoVFKM(pushState=false) {
    if (!$('.section-background-header h1 span').length) {
        $('.section-background-header h1').html($('.section-background-header h1').html()+"<span></span>");
    }
    var txt_append = "";
    var new_slug = "";
    if ($('#filter_select_marques').val()) {
        txt_append += $('#filter_select_marques option:selected').html()+" ";
        new_slug += $('#filter_select_marques option:selected').attr('data-slug')+'/';

        if ($('#filter_select_modeles').val()) {
            new_slug += $('#filter_select_modeles option:selected').attr('data-slug');
        }
    }
    if ($('#filter_select_modeles').val()) {
        txt_append += $('#filter_select_modeles option:selected').html();
    }
    $('.section-background-header h1 span').html(txt_append);
    if (pushState) {
        history.pushState({}, "", base_url+'vehicules-faiblement-kilometres/'+new_slug);
    }
}

function initOrderVO() {
    $('.order-vo-link').on('click', function(e) {
        console.log($(this).attr('data-orderBy'));
        $('#cars-filters-form input[name=orderBy]').val($(this).attr('data-orderBy'));
        $('#cars-filters-form').submit();
    });
}

function initInfoFinancement() {
    $('.container-items button.info-financement').on('click', function() {
        if ($('.content-financement-info').css('display') == "none") {
            $('.content-financement-info').slideDown();
        } else {
            $('.content-financement-info').slideUp();
        }
    });

    $('.container-price button.info-financement').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if ($(this).closest('.container-price').find('.infos-financement-fancybox').css('display') == "none") {
            $('.container-price .infos-financement-fancybox').fadeOut();
            $(this).closest('.container-price').find('.infos-financement-fancybox').fadeIn();
        } else {
            $('.container-price .infos-financement-fancybox').fadeOut();
        }
    });

}

function initFormFinancement() {

    if ($('.iframe-financement-container').length) {
        $('#btn-open-simulateur').on('click', function(e) {
            e.preventDefault();

            var csrf_name = $('#csrf_name').val();
            var csrf_hash = $('#csrf_hash').val();

            $('.iframe-financement-overlay').fadeIn();

            $.ajax({
                url: base_url+'financement/get_financement_iframe',
                data: {
                    vehicule_id: $(this).attr('data-id-vehicule'),
                    [csrf_name]: csrf_hash
                },
                method: "POST"

            }).done(function(data) {
                if (data != "") {
                    var retour = JSON.parse(data);

                    if (retour.url_simulateur) {

                        console.log(retour);

                        $('<iframe>').attr('id', "vo_iframe_financement").appendTo('.iframe-financement-container');
                        $('.iframe-financement-container iframe').hide();
                        $('.iframe-financement-container iframe').attr('src', retour.url_simulateur);
                        $('#btn-open-simulateur').fadeOut('slow', function() {
                            $('.iframe-financement-overlay').fadeOut();
                            $('.iframe-financement-container iframe').fadeIn('slow', function() {

                                var postDonnees = {
                                    jwtToken : retour.jwt_token,
                                    entreeSimulateur : retour.entree_simulateur,
                                    ovlEtablissementConfig : retour.ovl_etablissement_config
                                };
                                var postJSON = JSON.stringify(postDonnees);
                                var iframe = document.getElementById('vo_iframe_financement');

                                console.log('iframe créée, envoi des données : ');
                                console.log(postJSON);

                                // Event listener, sert à écouter l'enfant (l'Ifame) et recevoir ses messages
                                window.addEventListener('message', function (e) {
                                    console.log('message reçu window');
                                    if (e.data && (base_url === e.origin || base_url === e.origin+'/')) {
                                        console.log('Données reçu :');
                                        console.log(e);
                                    }
                                });

                                if (iframe) {
                                    iframe.contentWindow.postMessage(postJSON, '*');
                                }

                            });
                        });


                        $('.container-form .uk-alert-danger').fadeOut();
                    } else {
                        $('.iframe-financement-message .uk-alert-danger p').html("URL simulateur non trouvée.");
                        $('.iframe-financement-message .uk-alert-danger').fadeIn();
                    }

                } else {
                    $('.iframe-financement-message .uk-alert-danger p').html("Données récupérées non valides.");
                    $('.iframe-financement-message .uk-alert-danger').fadeIn();
                }

            }).fail(function() {
                console.log(data);
                $('.iframe-financement-overlay').fadeOut();
                $('.iframe-financement-message .uk-alert-danger p').html("Erreur lors du la récupération des données.");
                $('.iframe-financement-message .uk-alert-danger').fadeIn();
            });
        });

    }
}



const initReady = () => {

    openFilterListing();
    openRangeModalsDesktop();
    openRangeModalsMobile();
    rangeBudgetMobile();
    rangeBudgetDesktop();
    rangeMensualitesMobile();
    rangeMensualitesDesktop();
    openSearchMobile();
    openAssistanceMobile();
    openAccesRapideMobile();
    getResolution();
    mobileNav();
    accordeonFooter();
    moveContainerRsMobile();
    moveServiceItemMobile();
    openCarsFilterForm();
    moveContainerDetailsVehiculesMobile();
    openContactFormConcessions();
    openMapConcessions();
    KGTruncateText();
    filtersVehicules();
    filtersCarsForm();
    filtersContactForm();
    initReCaptcha();
    initFormAutosubmit();
    initFormVo();
    initVoPagination();
    initComparateur();
    initFonctionsVO();
    initSlideNumber();
    initFormRdv();
    initSEOListings();
    initOrderVO();
    initInfoFinancement();
    initFormFinancement();

    $(".input-date").flatpickr({
        "locale": "fr",
        "dateFormat": "d/m/Y"
    });
};

// Document ready
document.addEventListener('DOMContentLoaded', initReady);